import React from 'react';
import { PRODUCT_COOKIES } from '../constants';
import EssentialOnePage from '../pages/product/essential-one';

const EssentialOneTrial20Off: React.FC = () => {
  return (
    <EssentialOnePage
      noindex
      isLandingPage={true}
      isFreeTrial={true}
      partnership={PRODUCT_COOKIES.ESSENTIAL_ONE.value.ESSENTIAL_ONE_20_OFF_FREE_TRIAL}
    />
  );
};

export default EssentialOneTrial20Off;
